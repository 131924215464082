import * as _colors2 from "tailwindcss/colors";
var _colors = "default" in _colors2 ? _colors2.default : _colors2;
import * as _postcssJs2 from "postcss-js";
var _postcssJs = "default" in _postcssJs2 ? _postcssJs2.default : _postcssJs2;
import _addPrefix from "./lib/addPrefix";
import _package from "../package.json";
import _index from "./theming/index";
import _utilities from "../dist/utilities";
import _base from "../dist/base";
import _unstyled from "../dist/unstyled";
import _unstyled2 from "../dist/unstyled.rtl";
import _styled from "../dist/styled";
import _styled2 from "../dist/styled.rtl";
import _utilitiesUnstyled from "../dist/utilities-unstyled";
import _utilitiesStyled from "../dist/utilities-styled";
import _themes from "./theming/themes";
import _functions from "./theming/functions";
import * as _plugin2 from "tailwindcss/plugin";
var _plugin = "default" in _plugin2 ? _plugin2.default : _plugin2;
var exports = {};
const tailwindColors = _colors;
const postcssJs = _postcssJs;
const postcssPrefix = _addPrefix;
const daisyuiInfo = _package;
const colors = _index;
const utilities = _utilities;
const base = _base;
const unstyled = _unstyled;
const unstyledRtl = _unstyled2;
const styled = _styled;
const styledRtl = _styled2;
const utilitiesUnstyled = _utilitiesUnstyled;
const utilitiesStyled = _utilitiesStyled;
const themes = _themes;
const colorFunctions = _functions;
const mainFunction = ({
  addBase,
  addComponents,
  config
}) => {
  let logs = false;
  if (config("daisyui.logs") != false) {
    logs = true;
  }
  if (logs) {
    console.log();
    console.log("\x1B[35m%s\x1B[0m", "\uD83C\uDF3C daisyUI " + daisyuiInfo.version, "\x1B[0m" + daisyuiInfo.homepage);
  }

  // inject @base style
  if (config("daisyui.base") != false) {
    addBase(base);
  }

  // inject components
  // because rollupjs doesn't supprt dynamic require
  let file = styled;
  if (config("daisyui.styled") == false && config("daisyui.rtl") != true) {
    file = unstyled;
  } else if (config("daisyui.styled") == false && config("daisyui.rtl") == true) {
    file = unstyledRtl;
  } else if (config("daisyui.styled") != false && config("daisyui.rtl") != true) {
    file = styled;
  } else if (config("daisyui.styled") !== false && config("daisyui.rtl") == true) {
    file = styledRtl;
  }

  // add prefix to class names if specified
  const prefix = config("daisyui.prefix");
  let postcssJsProcess;
  if (prefix) {
    try {
      postcssJsProcess = postcssJs.sync(postcssPrefix({
        prefix,
        ignore: []
      }));
    } catch (error) {
      logs && console.error(`Error occurred and prevent applying the "prefix" option:`, error);
    }
  }
  const shouldApplyPrefix = prefix && postcssJsProcess;
  if (shouldApplyPrefix) {
    file = postcssJsProcess(file);
  }
  addComponents(file);
  const themeInjectorHsl = colorFunctions.injectThemes(addBase, config, themes, "hsl");
  themeInjectorHsl;

  // inject @utilities style needed by components
  if (config("daisyui.utils") != false) {
    addComponents(utilities, {
      variants: ["responsive"]
    });
    let toAdd = utilitiesUnstyled; // shadow clone here to avoid mutate the original
    if (shouldApplyPrefix) {
      toAdd = postcssJsProcess(toAdd);
    }
    addComponents(toAdd, {
      variants: ["responsive"]
    });
    toAdd = utilitiesStyled;
    if (shouldApplyPrefix) {
      toAdd = postcssJsProcess(toAdd);
    }
    addComponents(toAdd, {
      variants: ["responsive"]
    });
  }
  if (logs) {
    console.log("\u2570\u256E");
    if (config("daisyui.styled") == false) {
      console.log(" \u251C\u2500", "\x1B[33m" + "\u25C6" + "\x1B[0m" + "\x1B[2m", "daisyui.styled", "\x1B[0m" + "config is", "\x1B[2m" + "false" + "\x1B[0m", "\u2013 your components will have no design decisions" + "\n \u2502");
    }
    if (config("daisyui.utils") == false) {
      console.log(" \u251C\u2500", "\x1B[33m" + "\u25C6" + "\x1B[0m" + "\x1B[2m", "daisyui.utils", "\x1B[0m" + "config is", "\x1B[2m" + "false" + "\x1B[0m", "\u2013 daisyUI modifier utility classes are disabled" + "\n \u2502");
    }
    if (config("daisyui.prefix") && config("daisyui.prefix") !== "") {
      console.log(" \u251C\u2500", "\x1B[32m" + "\u2714\uFE0E" + "\x1B[0m", "Prefix is enabled, daisyUI classnames must use", "\x1B[2m" + `${config("daisyui.prefix")}`, "\x1B[0m" + "prefix. like:", "\x1B[2m" + `${config("daisyui.prefix")}btn`, "\x1B[0m" + "\n \u2502    https://daisyui.com/docs/config" + "\n \u2502");
    }
    if (config("daisyui.rtl") == true) {
      console.log(" \u251C\u2500", "\x1B[32m" + "\u2714\uFE0E" + "\x1B[0m", "Using RTL, make sure you're using", "\x1B[2m" + "<html dir=rtl>" + "\x1B[0m", "and you have", "\x1B[2m", "tailwindcss-flip", "\x1B[0m", "plugin", "\n \u2502  https://daisyui.com/docs/config" + "\n \u2502");
    }
    if (themeInjectorHsl.themeOrder.length > 0) {
      console.log(" \u2570\u2500", "\x1B[32m" + "\u2714\uFE0E" + "\x1B[0m", "\x1B[2m" + "[ " + "\x1B[0m" + `${themeInjectorHsl.themeOrder.length}` + "\x1B[2m" + " ]" + "\x1B[0m" + ` ${themeInjectorHsl.themeOrder.length > 1 ? "themes are" : "theme is"}` + ` enabled. You can add more themes or make your own theme:` + "\n      https://daisyui.com/docs/themes");
    }
    if (themeInjectorHsl.themeOrder.length === 0) {
      console.log(" \u2570\u2500", "\x1B[33m" + "\u25C6" + "\x1B[0m", `All themes are disabled in the config. You can add themes or make your own theme:` + "\n      https://daisyui.com/docs/themes");
    }
    console.log("\n\x1B[32m%s\x1B[0m", "    \u2764\uFE0E Support daisyUI" + "\x1B[0m" + `: ${daisyuiInfo.funding.url}`);
    console.log();
  }
};
exports = _plugin(mainFunction, {
  theme: {
    extend: {
      colors: {
        ...colors,
        // adding all Tailwind `neutral` shades here so they don't get overridden by daisyUI `neutral` color
        "neutral-50": tailwindColors.neutral[50],
        "neutral-100": tailwindColors.neutral[100],
        "neutral-200": tailwindColors.neutral[200],
        "neutral-300": tailwindColors.neutral[300],
        "neutral-400": tailwindColors.neutral[400],
        "neutral-500": tailwindColors.neutral[500],
        "neutral-600": tailwindColors.neutral[600],
        "neutral-700": tailwindColors.neutral[700],
        "neutral-800": tailwindColors.neutral[800],
        "neutral-900": tailwindColors.neutral[900]
      }
    }
  }
});
export default exports;
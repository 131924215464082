import * as _colorString2 from "color-string";

var _colorString = "default" in _colorString2 ? _colorString2.default : _colorString2;

import * as _colorConvert2 from "color-convert";

var _colorConvert = "default" in _colorConvert2 ? _colorConvert2.default : _colorConvert2;

var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};
const colorString = _colorString;
const convert = _colorConvert;
const skippedModels = [// To be honest, I don't really feel like keyword belongs in color convert, but eh.
"keyword", // Gray conflicts with some method names, and has its own method defined.
"gray", // Shouldn't really be in color-convert either...
"hex"];
const hashedModelKeys = {};

for (const model of Object.keys(convert)) {
  hashedModelKeys[[...convert[model].labels].sort().join("")] = model;
}

const limiters = {};

function Color(object, model) {
  if (!((this || _global) instanceof Color)) {
    return new Color(object, model);
  }

  if (model && model in skippedModels) {
    model = null;
  }

  if (model && !(model in convert)) {
    throw new Error("Unknown model: " + model);
  }

  let i;
  let channels;

  if (object == null) {
    // eslint-disable-line no-eq-null,eqeqeq
    (this || _global).model = "rgb";
    (this || _global).color = [0, 0, 0];
    (this || _global).valpha = 1;
  } else if (object instanceof Color) {
    (this || _global).model = object.model;
    (this || _global).color = [...object.color];
    (this || _global).valpha = object.valpha;
  } else if (typeof object === "string") {
    const result = colorString.get(object);

    if (result === null) {
      throw new Error("Unable to parse color from string: " + object);
    }

    (this || _global).model = result.model;
    channels = convert[(this || _global).model].channels;
    (this || _global).color = result.value.slice(0, channels);
    (this || _global).valpha = typeof result.value[channels] === "number" ? result.value[channels] : 1;
  } else if (object.length > 0) {
    (this || _global).model = model || "rgb";
    channels = convert[(this || _global).model].channels;
    const newArray = Array.prototype.slice.call(object, 0, channels);
    (this || _global).color = zeroArray(newArray, channels);
    (this || _global).valpha = typeof object[channels] === "number" ? object[channels] : 1;
  } else if (typeof object === "number") {
    // This is always RGB - can be converted later on.
    (this || _global).model = "rgb";
    (this || _global).color = [object >> 16 & 255, object >> 8 & 255, object & 255];
    (this || _global).valpha = 1;
  } else {
    (this || _global).valpha = 1;
    const keys = Object.keys(object);

    if ("alpha" in object) {
      keys.splice(keys.indexOf("alpha"), 1);
      (this || _global).valpha = typeof object.alpha === "number" ? object.alpha : 0;
    }

    const hashedKeys = keys.sort().join("");

    if (!(hashedKeys in hashedModelKeys)) {
      throw new Error("Unable to parse color from object: " + JSON.stringify(object));
    }

    (this || _global).model = hashedModelKeys[hashedKeys];
    const {
      labels
    } = convert[(this || _global).model];
    const color = [];

    for (i = 0; i < labels.length; i++) {
      color.push(object[labels[i]]);
    }

    (this || _global).color = zeroArray(color);
  } // Perform limitations (clamping, etc.)


  if (limiters[(this || _global).model]) {
    channels = convert[(this || _global).model].channels;

    for (i = 0; i < channels; i++) {
      const limit = limiters[(this || _global).model][i];

      if (limit) {
        (this || _global).color[i] = limit((this || _global).color[i]);
      }
    }
  }

  (this || _global).valpha = Math.max(0, Math.min(1, (this || _global).valpha));

  if (Object.freeze) {
    Object.freeze(this || _global);
  }
}

Color.prototype = {
  toString() {
    return this.string();
  },

  toJSON() {
    return this[(this || _global).model]();
  },

  string(places) {
    let self = (this || _global).model in colorString.to ? this || _global : this.rgb();
    self = self.round(typeof places === "number" ? places : 1);
    const args = self.valpha === 1 ? self.color : [...self.color, (this || _global).valpha];
    return colorString.to[self.model](args);
  },

  percentString(places) {
    const self = this.rgb().round(typeof places === "number" ? places : 1);
    const args = self.valpha === 1 ? self.color : [...self.color, (this || _global).valpha];
    return colorString.to.rgb.percent(args);
  },

  array() {
    return (this || _global).valpha === 1 ? [...(this || _global).color] : [...(this || _global).color, (this || _global).valpha];
  },

  object() {
    const result = {};
    const {
      channels
    } = convert[(this || _global).model];
    const {
      labels
    } = convert[(this || _global).model];

    for (let i = 0; i < channels; i++) {
      result[labels[i]] = (this || _global).color[i];
    }

    if ((this || _global).valpha !== 1) {
      result.alpha = (this || _global).valpha;
    }

    return result;
  },

  unitArray() {
    const rgb = this.rgb().color;
    rgb[0] /= 255;
    rgb[1] /= 255;
    rgb[2] /= 255;

    if ((this || _global).valpha !== 1) {
      rgb.push((this || _global).valpha);
    }

    return rgb;
  },

  unitObject() {
    const rgb = this.rgb().object();
    rgb.r /= 255;
    rgb.g /= 255;
    rgb.b /= 255;

    if ((this || _global).valpha !== 1) {
      rgb.alpha = (this || _global).valpha;
    }

    return rgb;
  },

  round(places) {
    places = Math.max(places || 0, 0);
    return new Color([...(this || _global).color.map(roundToPlace(places)), (this || _global).valpha], (this || _global).model);
  },

  alpha(value) {
    if (value !== undefined) {
      return new Color([...(this || _global).color, Math.max(0, Math.min(1, value))], (this || _global).model);
    }

    return (this || _global).valpha;
  },

  // Rgb
  red: getset("rgb", 0, maxfn(255)),
  green: getset("rgb", 1, maxfn(255)),
  blue: getset("rgb", 2, maxfn(255)),
  hue: getset(["hsl", "hsv", "hsl", "hwb", "hcg"], 0, value => (value % 360 + 360) % 360),
  saturationl: getset("hsl", 1, maxfn(100)),
  lightness: getset("hsl", 2, maxfn(100)),
  saturationv: getset("hsv", 1, maxfn(100)),
  value: getset("hsv", 2, maxfn(100)),
  chroma: getset("hcg", 1, maxfn(100)),
  gray: getset("hcg", 2, maxfn(100)),
  white: getset("hwb", 1, maxfn(100)),
  wblack: getset("hwb", 2, maxfn(100)),
  cyan: getset("cmyk", 0, maxfn(100)),
  magenta: getset("cmyk", 1, maxfn(100)),
  yellow: getset("cmyk", 2, maxfn(100)),
  black: getset("cmyk", 3, maxfn(100)),
  x: getset("xyz", 0, maxfn(95.047)),
  y: getset("xyz", 1, maxfn(100)),
  z: getset("xyz", 2, maxfn(108.833)),
  l: getset("lab", 0, maxfn(100)),
  a: getset("lab", 1),
  b: getset("lab", 2),

  keyword(value) {
    if (value !== undefined) {
      return new Color(value);
    }

    return convert[(this || _global).model].keyword((this || _global).color);
  },

  hex(value) {
    if (value !== undefined) {
      return new Color(value);
    }

    return colorString.to.hex(this.rgb().round().color);
  },

  hexa(value) {
    if (value !== undefined) {
      return new Color(value);
    }

    const rgbArray = this.rgb().round().color;
    let alphaHex = Math.round((this || _global).valpha * 255).toString(16).toUpperCase();

    if (alphaHex.length === 1) {
      alphaHex = "0" + alphaHex;
    }

    return colorString.to.hex(rgbArray) + alphaHex;
  },

  rgbNumber() {
    const rgb = this.rgb().color;
    return (rgb[0] & 255) << 16 | (rgb[1] & 255) << 8 | rgb[2] & 255;
  },

  luminosity() {
    // http://www.w3.org/TR/WCAG20/#relativeluminancedef
    const rgb = this.rgb().color;
    const lum = [];

    for (const [i, element] of rgb.entries()) {
      const chan = element / 255;
      lum[i] = chan <= 0.04045 ? chan / 12.92 : ((chan + 0.055) / 1.055) ** 2.4;
    }

    return 0.2126 * lum[0] + 0.7152 * lum[1] + 0.0722 * lum[2];
  },

  contrast(color2) {
    // http://www.w3.org/TR/WCAG20/#contrast-ratiodef
    const lum1 = this.luminosity();
    const lum2 = color2.luminosity();

    if (lum1 > lum2) {
      return (lum1 + 0.05) / (lum2 + 0.05);
    }

    return (lum2 + 0.05) / (lum1 + 0.05);
  },

  level(color2) {
    // https://www.w3.org/TR/WCAG/#contrast-enhanced
    const contrastRatio = this.contrast(color2);

    if (contrastRatio >= 7) {
      return "AAA";
    }

    return contrastRatio >= 4.5 ? "AA" : "";
  },

  isDark() {
    // YIQ equation from http://24ways.org/2010/calculating-color-contrast
    const rgb = this.rgb().color;
    const yiq = (rgb[0] * 2126 + rgb[1] * 7152 + rgb[2] * 722) / 10000;
    return yiq < 128;
  },

  isLight() {
    return !this.isDark();
  },

  negate() {
    const rgb = this.rgb();

    for (let i = 0; i < 3; i++) {
      rgb.color[i] = 255 - rgb.color[i];
    }

    return rgb;
  },

  lighten(ratio) {
    const hsl = this.hsl();
    hsl.color[2] += hsl.color[2] * ratio;
    return hsl;
  },

  darken(ratio) {
    const hsl = this.hsl();
    hsl.color[2] -= hsl.color[2] * ratio;
    return hsl;
  },

  saturate(ratio) {
    const hsl = this.hsl();
    hsl.color[1] += hsl.color[1] * ratio;
    return hsl;
  },

  desaturate(ratio) {
    const hsl = this.hsl();
    hsl.color[1] -= hsl.color[1] * ratio;
    return hsl;
  },

  whiten(ratio) {
    const hwb = this.hwb();
    hwb.color[1] += hwb.color[1] * ratio;
    return hwb;
  },

  blacken(ratio) {
    const hwb = this.hwb();
    hwb.color[2] += hwb.color[2] * ratio;
    return hwb;
  },

  grayscale() {
    // http://en.wikipedia.org/wiki/Grayscale#Converting_color_to_grayscale
    const rgb = this.rgb().color;
    const value = rgb[0] * 0.3 + rgb[1] * 0.59 + rgb[2] * 0.11;
    return Color.rgb(value, value, value);
  },

  fade(ratio) {
    return this.alpha((this || _global).valpha - (this || _global).valpha * ratio);
  },

  opaquer(ratio) {
    return this.alpha((this || _global).valpha + (this || _global).valpha * ratio);
  },

  rotate(degrees) {
    const hsl = this.hsl();
    let hue = hsl.color[0];
    hue = (hue + degrees) % 360;
    hue = hue < 0 ? 360 + hue : hue;
    hsl.color[0] = hue;
    return hsl;
  },

  mix(mixinColor, weight) {
    // Ported from sass implementation in C
    // https://github.com/sass/libsass/blob/0e6b4a2850092356aa3ece07c6b249f0221caced/functions.cpp#L209
    if (!mixinColor || !mixinColor.rgb) {
      throw new Error("Argument to \"mix\" was not a Color instance, but rather an instance of " + typeof mixinColor);
    }

    const color1 = mixinColor.rgb();
    const color2 = this.rgb();
    const p = weight === undefined ? 0.5 : weight;
    const w = 2 * p - 1;
    const a = color1.alpha() - color2.alpha();
    const w1 = ((w * a === -1 ? w : (w + a) / (1 + w * a)) + 1) / 2;
    const w2 = 1 - w1;
    return Color.rgb(w1 * color1.red() + w2 * color2.red(), w1 * color1.green() + w2 * color2.green(), w1 * color1.blue() + w2 * color2.blue(), color1.alpha() * p + color2.alpha() * (1 - p));
  }

}; // Model conversion methods and static constructors

for (const model of Object.keys(convert)) {
  if (skippedModels.includes(model)) {
    continue;
  }

  const {
    channels
  } = convert[model]; // Conversion methods

  Color.prototype[model] = function (...args) {
    if ((this || _global).model === model) {
      return new Color(this || _global);
    }

    if (args.length > 0) {
      return new Color(args, model);
    }

    return new Color([...assertArray(convert[(this || _global).model][model].raw((this || _global).color)), (this || _global).valpha], model);
  }; // 'static' construction methods


  Color[model] = function (...args) {
    let color = args[0];

    if (typeof color === "number") {
      color = zeroArray(args, channels);
    }

    return new Color(color, model);
  };
}

function roundTo(number, places) {
  return Number(number.toFixed(places));
}

function roundToPlace(places) {
  return function (number) {
    return roundTo(number, places);
  };
}

function getset(model, channel, modifier) {
  model = Array.isArray(model) ? model : [model];

  for (const m of model) {
    (limiters[m] || (limiters[m] = []))[channel] = modifier;
  }

  model = model[0];
  return function (value) {
    let result;

    if (value !== undefined) {
      if (modifier) {
        value = modifier(value);
      }

      result = this[model]();
      result.color[channel] = value;
      return result;
    }

    result = this[model]().color[channel];

    if (modifier) {
      result = modifier(result);
    }

    return result;
  };
}

function maxfn(max) {
  return function (v) {
    return Math.max(0, Math.min(max, v));
  };
}

function assertArray(value) {
  return Array.isArray(value) ? value : [value];
}

function zeroArray(array, length) {
  for (let i = 0; i < length; i++) {
    if (typeof array[i] !== "number") {
      array[i] = 0;
    }
  }

  return array;
}

exports = Color;
export default exports;